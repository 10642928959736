import React, { useState } from "react";
import { useWalletModal } from "@solana/wallet-adapter-react-ui";
import { useWallet } from "@solana/wallet-adapter-react";
import { useWeb3Modal, useWeb3ModalAccount } from "@web3modal/ethers5/react";

function CustomConnectButton() {
  const { setVisible } = useWalletModal();
  const { disconnect, connected, publicKey } = useWallet();
  const { open } = useWeb3Modal();
  const { address, isConnected } = useWeb3ModalAccount();
  const [selectedOption, setSelectedOption] = useState("eth");
  const options = [
    {
      label: "Ethereum",
      value: "eth",
    },
    { label: "Solana", value: "sol" },
  ];

  function formatAddress(address) {
    const start = address.substring(0, 3);
    const end = address.substring(address.length - 5);
    return `${start}...${end}`;
  }
  return (
    <div>
      {!isConnected && !connected ? (
        <div style={{ display: "flex", gap: "10px" }}>
          <select
            type="button"
            value={selectedOption}
            className="btn btn-light"
            onChange={(e) => setSelectedOption(e.target.value)}
            // onClick={handleChange}
          >
            {options.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <button
            className="btn btn-light"
            onClick={() =>
              selectedOption == "eth" ? open() : setVisible(true)
            }
          >
            Connect Wallet
          </button>
        </div>
      ) : (
        <div style={{ display: "flex", gap: "10px" }}>
          <button
            className="btn btn-light"
            onClick={() => (isConnected ? open() : disconnect())}
          >
            {isConnected
              ? formatAddress(address)
              : formatAddress(publicKey.toBase58())}
          </button>
        </div>
      )}
    </div>
  );
}
export default CustomConnectButton;
