import { useWallet } from "@solana/wallet-adapter-react";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import { ethers } from "ethers";
import React, { useEffect, useState } from "react";
import CustomConnectButton from "../CustomConnectButton";
import bs58 from "bs58";

export const TokenList = () => {
  const { wallet, publicKey, connected, signMessage } = useWallet();
  const { address, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [tokenList, setTokenList] = useState([]);

  const signForProfile = async (account) => {
    try {
      console.log("signForProfile");
      let provider = new ethers.providers.Web3Provider(walletProvider);
      const message = process.env.REACT_APP_AUTH_MSG;
      const messageBytes = ethers.utils.toUtf8Bytes(message);
      const hashedMessage = ethers.utils.keccak256(messageBytes);

      const signer = provider.getSigner(account);
      const signature = await signer.signMessage(
        ethers.utils.arrayify(hashedMessage)
      );

      let apiBody = {
        signature: signature,
        walletAddress: account,
        blockchain: "ethereum",
      };

      const apiResponse = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(apiBody),
        }
      );
      let res = await apiResponse.json();
      if (res.accessToken) {
        localStorage.setItem("token", res.accessToken);
      }
      //   return { hashedMessage, signature };
    } catch (error) {
      return {
        hashedMessage: error.message,
        signature: error.message,
        err: true,
      };
    }
  };

  const signForPhantom = async (wallet) => {
    try {
      console.log("signForPhantom");
      const message = process.env.REACT_APP_AUTH_MSG;
      const messageBytes = new TextEncoder().encode(message);

      const signature = await signMessage(messageBytes);
      const ss = bs58.encode(signature);

      let apiBody = {
        signature: ss,
        walletAddress: publicKey.toBase58(),
        blockchain: "solana",
      };

      const apiResponse = await fetch(
        `${process.env.REACT_APP_API_URL}api/auth/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(apiBody),
        }
      );
      let res = await apiResponse.json();
      if (res.accessToken) {
        localStorage.setItem("token", res.accessToken);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  async function fetchData(wallet, address) {
    try {
      // for metamask
      if (address) {
        console.log("address", address);
        await signForProfile(address);
      }
      // for phantom
      console.log("connected", connected);
      console.log("wallet", wallet);
      if (wallet) {
        console.log("wallet", wallet);
        await signForPhantom(wallet);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  async function fetchTokenList() {
    try {
      const data = await fetch(
        `${process.env.REACT_APP_API_URL}` +
          `api/users/leaderboard?page=1&limit=10`
      );
      const jsonData = await data.json();
      console.log("jsonData", jsonData);
      setTokenList(jsonData);
    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    if (!isConnected && !connected) {
      localStorage.removeItem("token");
    }
  }, [isConnected, connected]);

  useEffect(() => {
    fetchTokenList();
  }, []);

  useEffect(() => {
    fetchData(wallet, address);
    // eslint-disable-next-line
  }, [wallet, address]);

  function handlePlayGame() {
    try {
      let token = localStorage.getItem("token");
      if (!token) {
        alert("Please connect to your wallet to play game");
        return;
      } else {
        alert("Play Game" + token);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  return (
    <div className="main">
      <header className="main-header">
        <div className="container-fluid">
          <div className="nav-row">
            <CustomConnectButton />
          </div>
        </div>
      </header>
      <div className="main-content">
        <div className="container">
          <div className="play-btn">
            <button
              type="button"
              onClick={handlePlayGame}
              className=" play-link btn btn-outline-dark"
            >
              Play Game
            </button>
          </div>
          <div className="data-wrap">
            <h2 className="heading">
              Token Tracker: Managing Your Cryptocurrency Investments
            </h2>
            <table className="table ">
              <thead>
                <tr className="heading-tr">
                  <th className="heading-th" scope="col">
                    Rank
                  </th>
                  <th className="heading-th" scope="col">
                    Wallet Address
                  </th>
                  <th className="heading-th" scope="col">
                    Score
                  </th>
                </tr>
              </thead>
              <tbody className="tbody">
                {tokenList.length
                  ? tokenList?.map((data, index) => (
                      <tr key={index}>
                        <th className="heading-th" scope="row">
                          {index + 1}
                        </th>
                        <td className="heading-th">{data.walletAddress}</td>
                        <td className="heading-th">{data.score}</td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
